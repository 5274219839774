import * as React from 'react';
import { Link as GatsbyLink, graphql } from 'gatsby';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import posed from 'react-pose';
import App from '../components/layout/App';
import Wrapper from '../components/base/Wrapper';
import Content from '../components/base/Content';
import Link from '../components/base/Link';

import {
  color,
  mqDown,
  breakpoint,
  header,
  mqUp,
  fontSize,
  lineHeight,
  fontWeight,
} from '../constants/styles';

import prev from '../images/prev.svg';
import next from '../images/next.svg';
import down from '../images/down.png';
import up from '../images/up.png';

interface ReaslisationPostProps {
  rest: any;
  location: any;
  pageContext: any;
  data: {
    content: {
      data: {
        title: string;
        description: {
          html: string;
        };
        gallery: [
          {
            img: {
              alt: string;
              url: string;
            };
          },
        ];
      };
    };
  };
}

const TopLink = styled(GatsbyLink)`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 50%;
  font-size: 0;
  cursor: url(${up}), auto;
`;

const BottomLink = styled(GatsbyLink)`
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 50%;
  font-size: 0;
  cursor: url(${down}), auto;
`;

const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 6rem;
  a {
    display: block;
    height: 6rem;
    line-height: 6rem;
    background-repeat: no-repeat;
    background-size: 2.6rem;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    color: ${color.clr1};
    &:hover {
      opacity: 0.5;
    }
    ${mqDown(breakpoint.mainNav)} {
      font-size: 1.4rem;
      background-size: 2rem;
    }
  }
`;

const HeroPose = posed.div({
  open: {
    applyAtStart: { display: 'flex' },
    opacity: 1,
  },
  close: {
    applyAtEnd: { display: 'none' },
    opacity: 0,
  },
});

const Hero = styled(HeroPose)`
  z-index: 2;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #fff;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: calc(2vh + ${header.heightSmall}) 2rem;
  text-align: center;
  overflow: hidden;
  ${mqUp(breakpoint.mainNav)} {
    padding: calc(2vh + ${header.heightBase}) 2rem;
  }
  p {
    font-weight: ${fontWeight.regular};
    line-height: ${lineHeight.L};
    font-size: ${fontSize.XXXL};
  }
`;

const ReaslisationPost: React.SFC<ReaslisationPostProps> = ({
  data,
  pageContext,
  location,
  ...rest
}) => {
  const DATA = data.content.data;
  const createContentMarkup = () => {
    return { __html: DATA.description.html };
  };

  const [heroOpen, setHeroOpen] = React.useState(true);
  const heroTimeout = () => {
    setTimeout(() => {
      setHeroOpen(false);
    }, 1000);
  };
  heroTimeout();
  return (
    <App title={DATA.title} {...rest}>
      <Hero pose={heroOpen ? 'open' : 'close'}>
        <p>{DATA.title}</p>
      </Hero>
      {DATA.gallery.map((item, index) => (
        <div
          id={`img-${index}`}
          key={`img-${index}`}
          css={css`
            position: relative;
            display: flex;
            align-content: center;
            align-items: center;
            height: 100vh;
            min-height: 400px;
            padding: calc(2vh + ${header.heightSmall}) 0;
            ${mqUp(breakpoint.mainNav)} {
              padding: calc(2vh + ${header.heightBase}) 0;
            }
          `}
        >
          <Wrapper>
            <img
              src={item.img.url}
              alt={item.img.alt}
              css={css`
                display: block;
                width: auto;
                margin: auto;
                max-height: calc(96vh - (${header.heightSmall} * 2));
                ${mqUp(breakpoint.mainNav)} {
                  max-height: calc(96vh - (${header.heightBase} * 2));
                }
              `}
            />
          </Wrapper>
          {index > 0 && (
            <TopLink to={`${location.pathname}#img-${index - 1}`}>
              photo précédente
            </TopLink>
          )}
          {index < DATA.gallery.length - 1 && (
            <BottomLink to={`${location.pathname}#img-${index + 1}`}>
              photo suivante
            </BottomLink>
          )}
          {index + 1 === DATA.gallery.length && (
            <BottomLink to={`${location.pathname}#description-projet`}>
              description du projet
            </BottomLink>
          )}
        </div>
      ))}
      <Wrapper size="S">
        <Content
          id="description-projet"
          css={css`
            padding-top: 8rem;
            padding-bottom: 8rem;
          `}
        >
          <h1
            css={css`
              text-align: center;
            `}
          >
            {DATA.title}
          </h1>
          <div
            dangerouslySetInnerHTML={createContentMarkup()}
            css={css`
              margin-top: 6rem;
            `}
          />
        </Content>
        <Nav>
          <div>
            {pageContext.prev.uid && (
              <Link
                to={`/realisations/${pageContext.prev.uid}`}
                css={css`
                  background-image: url(${prev});
                  background-position: center left;
                  padding-left: 4rem;
                  ${mqDown(breakpoint.mainNav)} {
                    padding-left: 3rem;
                  }
                `}
              >
                Projet précédent
              </Link>
            )}
          </div>
          <div>
            {pageContext.next.uid && (
              <Link
                to={`/realisations/${pageContext.next.uid}`}
                css={css`
                  background-image: url(${next});
                  background-position: center right;
                  padding-right: 4rem;
                  ${mqDown(breakpoint.mainNav)} {
                    padding-right: 3rem;
                  }
                `}
              >
                Projet Suivant
              </Link>
            )}
          </div>
        </Nav>
      </Wrapper>
    </App>
  );
};

export default ReaslisationPost;

export const ReaslisationPostQuery = graphql`
  query PostBySlug($uid: String!) {
    content: prismicProject(uid: { eq: $uid }) {
      uid
      data {
        title
        description {
          html
        }
        gallery {
          img {
            alt
            url
          }
        }
      }
    }
  }
`;
